import React, { useState, useEffect } from "react";
import AuthLayout from "../../layouts/AuthLayout";
import { useFormik } from "formik";
import * as yup from "yup";
import { message } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { CiBellOn, CiCircleQuestion} from "react-icons/ci";
import { useAppSelector } from "../../store/hooks";
import { appAxios } from "../../api/axios";
import AuthImage2 from "../../assets/images/backgrounds/AuthImage2.png";
import { Link } from "react-router-dom";
import Tooltip from "../Tooltip";

const ResetPasswordForm = () => {
	// const { token } = useAppSelector((state) => state.form);
	// const token = useAppSelector((state) => state.plan.token);
	const [token, setToken] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [completePassword, setCompletePassword] = useState(false);
	const [checkPassword, setCheckPassword] = useState(false);
	let navigate = useNavigate();
	const location = useLocation();

	const formik = useFormik({
		initialValues: {
			newPassword: "",
			confirmPassword: "",
		},
		validationSchema: yup.object({
			newPassword: yup.string().required("Password is required!"),
			confirmPassword: yup
				.string()
				.oneOf([yup.ref("newPassword"), null], "Passwords must match!")
				.required("Password is required!"),
		}),
		onSubmit: async (values) => {
			// Handle form submission
			try {
				const response = await appAxios.get(
					`/v1/auth/validate-reset-token?token=${token}`
				);
				if (
					response.data.statusCode === 200 &&
					response.data.success === true
				) {
					// message.success(response?.data?.message);
					handleResetPassword(values);
				}
			} catch (error) {
				setErrorMessage(error?.response?.data?.message?.message);
				// message.error(error?.response?.data?.message?.message);
			}
		},
	});

	const handleResetPassword = async (values) => {
		const payload = {
			newPassword: values.newPassword,
			confirmPassword: values.confirmPassword,
			token: token,
		};
		try {
			const response = await appAxios.post("/v1/auth/update-password", payload);
			if (response.data.statusCode === 200 && response.data.success === true) {
				// message.success(response?.data?.message);
				setErrorMessage(response?.data?.message);
				setCompletePassword(true);
			} else {
				message.error(response?.data?.message);
				setCompletePassword(false);
			}
		} catch (error) {
			console.error("Form submission error:", error);
			message.error(error?.response?.data?.message?.message);
		}
	};

	useEffect(() => {
		const urlParams = new URLSearchParams(location.search);
		const tokenFromUrl = urlParams.get("token");
		if (tokenFromUrl) {
			setToken(tokenFromUrl);
		}
	}, []);

	const handleNewPasswordVisibilityToggle = () => {
		setShowPassword(!showPassword);
	};

	const handleConfirmPasswordVisibilityToggle = () => {
		setCheckPassword(!checkPassword);
	};

	return (
		<AuthLayout
			title="Reset your password"
			description="Don’t worry, we’ve got you. Enter your email, and we’ll send instructions to reset your password."
			backgroundImage={AuthImage2}
			backgroundText="It's okay. We all forget our passwords."
			additionalInfo={
				<div className="text-start pt-6">
					<Link to="/onboarding">
						Need an Account? <span className="text-[#9cabd7]">Sign up</span>
					</Link>
				</div>
			}
			buzzSales={
				<button
					type="button"
					className="text-gray-900 mt-5 flex flex-row items-center gap-2 bg-white border border-slate-500 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-md text-sm px-5 py-2.5 me-2 mb-2"
				>
					<CiBellOn /> Buzz the sales team
				</button>
			}
		>
			{/* Form Content */}
			{!completePassword ? (
				<form onSubmit={formik.handleSubmit} className="space-y-5 text-xs">
					<div>
						<label className="font-medium flex flex-row items-center justify-between gap-2">
							<p className="flex flex-row items-center gap-2">
								New Password{" "}
								<Tooltip text="Enter your account password. Minimum of 8 characters.">
									<CiCircleQuestion className="cursor-pointer" />
								</Tooltip>
							</p>
							{formik.touched.newPassword && formik.errors.newPassword ? (
								<p className="text-[#D61B1B] text-[10px]">
									{formik.errors.newPassword}
								</p>
							) : null}
						</label>
						<div className="relative">
							<input
								type={showPassword ? "text" : "password"}
								name="newPassword"
								placeholder="*************"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.newPassword}
								className="w-full mt-2 px-3 py-2 bg-[#ffffff] text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
							/>
							<span
								onClick={handleNewPasswordVisibilityToggle}
								className="absolute mt-1 right-2 top-1/2 -translate-y-1/2 cursor-pointer"
							>
								{showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
							</span>
						</div>
					</div>
					<div>
						<label className="font-medium flex flex-row items-center justify-between gap-2">
							<p className="flex flex-row items-center gap-2">
								Confirm Password{" "}
								<Tooltip text="Enter your account password. Minimum of 8 characters.">
									<CiCircleQuestion className="cursor-pointer" />
								</Tooltip>
							</p>
							{formik.touched.confirmPassword &&
							formik.errors.confirmPassword ? (
								<p className="text-[#D61B1B] text-[10px]">
									{formik.errors.confirmPassword}
								</p>
							) : null}
						</label>
						<div className="relative">
							<input
								type={checkPassword ? "text" : "password"}
								name="confirmPassword"
								placeholder="**************"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.confirmPassword}
								className="w-full mt-2 px-3 py-2 bg-[#ffffff] text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
							/>
							<span
								onClick={handleConfirmPasswordVisibilityToggle}
								className="absolute mt-1 right-2 top-1/2 -translate-y-1/2 cursor-pointer"
							>
								{checkPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
							</span>
						</div>
						{errorMessage && (
							<div className=" space-y-2 flex gap-2 items-center justify-end my-[0px] ">
								<p className="text-[#D61B1B] text-[10px] my-[0px] text-end">
									{errorMessage}
								</p>
							</div>
						)}
					</div>
					<button
						type="submit"
						className="w-full px-4 py-2 text-white font-medium bg-[#b0b5c9] hover:bg-indigo-500 active:bg-indigo-600 rounded-lg duration-150"
					>
						Reset Password
					</button>
				</form>
			) : (
				<>
				{errorMessage && (
					<div className="my-5 flex gap-1 items-center">
					<IoIosCheckmarkCircle className="w-5 h-5 text-green-600" />
					<p className="font-bold">{errorMessage}</p>
					</div>
				)}
				<div className="text-start pt-6">
					<Link to="/">
					Click here to{" "}
					<span className="text-[#9cabd7] hover:underline">Sign in</span>
					</Link>
				</div>
				</>
			)}
		</AuthLayout>
	);
};

export default ResetPasswordForm;
