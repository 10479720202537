import React from "react";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
	const navigate = useNavigate();

	const handleLogout = () => {
		localStorage.removeItem("accessToken");
		navigate("/");
	};

	return (
		<div>
			<h1>Dashboard</h1>
			{/* <button
				onClick={handleLogout}
				className="mt-5 px-4 py-2 text-white font-medium bg-[#b0b5c9] hover:bg-indigo-500 active:bg-indigo-600 rounded-lg duration-150"
			>
				Logout
			</button> */}
		</div>
	);
};

export default Dashboard;
