// src/store/slices/planSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	selectedPlan: "annually", // Default selected plan
	pricingInfo: {},
	token: "",
};

const planSlice = createSlice({
	name: "plan",
	initialState,
	reducers: {
		selectPlan: (state, action) => {
			state.selectedPlan = action.payload; // Update selected plan
		},
		savePricingInfo: (state, action) => {
			state.pricingInfo = action.payload; // Save pricing info for the selected plan
		},
		setToken: (state, action) => {
			state.token = action.payload;
		},
	},
});

export const { selectPlan, savePricingInfo, setToken } = planSlice.actions;

export default planSlice.reducer;
