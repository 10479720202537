import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	personalInfo: {},
	businessInfo: {},
	pricingInfo: {},
	paymentInfo: {},
	agreementInfo: {},
};

const formSlice = createSlice({
	name: "form",
	initialState,
	reducers: {
		savePersonalInfo: (state, action) => {
			state.personalInfo = action.payload;
		},
		saveBusinessInfo: (state, action) => {
			state.businessInfo = action.payload;
		},
		savePricingInfo: (state, action) => {
			state.pricingInfo = action.payload;
		},
		savePaymentInfo: (state, action) => {
			state.paymentInfo = action.payload;
		},
		saveAgreementInfo: (state, action) => {
			state.agreementInfo = action.payload;
		},

		resetForm: () => initialState,
	},
});

export const {
	savePersonalInfo,
	saveBusinessInfo,
	savePricingInfo,
	savePaymentInfo,
	saveAgreementInfo,
	resetForm,
} = formSlice.actions;

export default formSlice.reducer;
