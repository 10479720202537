import { appAxios } from "./api/axios";

export const isTokenValid = async (token) => {
	try {
		const response = await appAxios.get(
			`/v1/auth/validate-reset-token?token=${token}`
		);
		return response.data.statusCode === 200 && response.data.success === true;
	} catch (error) {
		console.error(
			"Token validation error:",
			error?.response?.data?.message?.message
		);
		return false;
	}
};
