import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { isTokenValid } from "../util";

const ProtectedRoute = ({ element }) => {
	const navigate = useNavigate();
	useEffect(() => {
		const checkAuth = async () => {
			const token = localStorage.getItem("accessToken");
			if (!token) {
				navigate("/");
				return;
			}
			const valid = await isTokenValid(token);
			if (!valid) {
				localStorage.removeItem("accessToken");
				navigate("/");
			}
		};
		checkAuth();
	}, [navigate]);

	return element;
};
export default ProtectedRoute;
