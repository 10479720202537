import React from "react";
import Logo from "../assets/images/logo/logo.png";
// import { CiBellOn, CiCircleQuestion } from "react-icons/ci";
// import { Link } from "react-router-dom";

const AuthLayout = ({
	title,
	description,
	formFields,
	buttonText,
	children,
	backgroundImage,
	backgroundText,
	additionalInfo,
	buzzSales,
}) => {
	return (
		<div className="h-screen w-full lg:px-12 lg:py-6 p-4 mx-auto ">
			<div className="grid grid-cols-6 gap-12 h-full">
				{/* Left section with form and details */}
				<div className="lg:col-span-4 m-auto h-full lg:block flex flex-col justify-center col-span-6 bg-[#f4f4f4] p-12 rounded-[50px] border border-[#cbceda]">
					<img src={Logo} alt="Logo" className="h-10 object-contain" />
					<div className="text-start">
						<div className="mt-5 space-y-2">
							<p className="text-gray-800 text-[24px] font-bold">{title}</p>
							<p className="text-sm">{description}</p>
						</div>
					</div>
					<div className="mt-4 lg:space-y-5 space-y-12 text-xs">{children}</div>
					{additionalInfo && (
						<div className="text-start pt-12">{additionalInfo}</div>
					)}
					<>{buzzSales && buzzSales}</>
				</div>
				<div className="col-span-2 lg:block hidden relative">
					<div className="absolute inset-0 flex items-center justify-center">
						<h1 className="text-white font-extrabold text-2xl text-center">
							{backgroundText}
						</h1>
					</div>
					<img
						src={backgroundImage}
						className="h-[94vh] object-cover rounded-[50px] w-full"
						alt=""
					/>
				</div>
			</div>
		</div>
	);
};

export default AuthLayout;
