import React, { useState } from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";

const MultiStep = () => {
	const [step, setStep] = useState(1);
	const [apiComplete, setApiComplete] = useState(false);
	const [progressMessage, setProgressMessage] = useState("");

	const nextStep = () => setStep((prevStep) => prevStep + 1);
	const prevStep = () => setStep((prevStep) => prevStep - 1);

	return (
		<div>
			{step === 1 && <Step1 nextStep={nextStep} />}
			{step === 2 && <Step2 nextStep={nextStep} prevStep={prevStep} />}
			{step === 3 && <Step3 nextStep={nextStep} prevStep={prevStep} />}
			{step === 4 && <Step4 nextStep={nextStep} prevStep={prevStep} />}
			{step === 5 && (
				<Step5
					nextStep={nextStep}
					prevStep={prevStep}
					setApiComplete={setApiComplete}
					setProgressMessage={setProgressMessage}
				/>
			)}
			{step === 6 && (
				<Step6
					nextStep={nextStep}
					prevStep={prevStep}
					apiComplete={apiComplete}
					progressMessage={progressMessage}
				/>
			)}
		</div>
	);
};

export default MultiStep;
