import React from "react";
import { CiBellOn } from "react-icons/ci";
import AuthLayout from "../../layouts/AuthLayout";
import AuthImage2 from "../../assets/images/backgrounds/AuthImage2.png";
import { Link } from "react-router-dom";

const ResetPasswordComplete = () => {
	return (
		<AuthLayout
			title="Reset password complete"
			description="You’re all set! Your password has been updated. Log in to get back to work."
			backgroundImage={AuthImage2}
			backgroundText="It's okay. We all forget our passwords."
			additionalInfo={
				<Link to="/onboarding">
					Need an Account?{" "}
					<span className="text-[#9cabd7] hover:underline">Sign up</span>
				</Link>
			}
			buzzSales={
				<button
					type="button"
					className="text-gray-900 mt-5 flex flex-row items-center gap-2 bg-white border border-slate-500 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-md text-sm px-5 py-2.5 me-2 mb-2"
				>
					<CiBellOn /> Buzz the sales team
				</button>
			}
		>
			<>
				{/* Main Content as children */}
				<div className="mt-5 space-y-2">
					<p className="font-bold pt-12">
						Please check your email for the reset link.
					</p>
				</div>
			</>
		</AuthLayout>
	);
};

export default ResetPasswordComplete;
