// axios.jsx

import axios from 'axios';
import { API_URL } from "../environmentVariable";

const baseURL = API_URL || "http://172.232.24.149:3000";

const apiResource = () => {
	const api = axios.create({
		baseURL,
		headers: {
			"Content-Type": "application/json",
		},
		withCredentials: false,
	});

	return api;
};

export const appAxios = apiResource();


