import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { message } from "antd";
import Logo from "../../assets/images/logo/logo.png";
import { CiBellOn, CiCircleQuestion } from "react-icons/ci";
import { IoIosCheckmarkCircle } from "react-icons/io";
import AuthImage2 from "../../assets/images/backgrounds/AuthImage2.png";
import { Link, Navigate } from "react-router-dom";
import AuthLayout from "../../layouts/AuthLayout";
import { useNavigate } from "react-router-dom";
import { appAxios } from "../../api/axios";
import Tooltip from "../Tooltip";

const ResetPassword = () => {
	const [errorShowing, setErrorShowing] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [passwordReset, setPasswordReset] = useState(false);
	let navigate = useNavigate();

	const formik = useFormik({
		initialValues: {
			email: "",
		},
		validationSchema: yup.object({
			email: yup
				.string()
				.email("Invalid email address!")
				.required("Email required!"),
		}),
		onSubmit: async (values) => {
			// Handle form submission
			const payload = {
				email: values.email,
			};
			try {
				const response = await appAxios.post(
					"/v1/auth/reset-password",
					payload
				);
				if (
					response.data.statusCode === 200 &&
					response.data.success === true
				) {
					setErrorMessage(response?.data?.message);
					setPasswordReset(true);
				} else {
					setPasswordReset(false);
				}
			} catch (error) {
				if (error?.response?.data?.message?.message === "Email not found!") {
					setErrorShowing(true);
				}
			}
		},
	});

	return (
		<AuthLayout
			title="Reset your password"
			description="Don’t worry, we’ve got you. Enter your email, and we’ll send instructions to reset your password."
			backgroundImage={AuthImage2}
			backgroundText="It's okay. We all forget our passwords."
			additionalInfo={
				<Link to="/onboarding">
					Need an Account?{" "}
					<span className="text-[#9cabd7] hover:underline">Sign up</span>
				</Link>
			}
			buzzSales={
				<button
					type="button"
					className="text-gray-900 mt-5 flex flex-row items-center gap-2 bg-white border border-slate-500 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-md text-sm px-5 py-2.5 me-2 mb-2"
				>
					<CiBellOn /> Buzz the sales team
				</button>
			}
		>
			{!passwordReset ? (
				<form onSubmit={formik.handleSubmit} className="space-y-5 text-xs">
					<div className="mb-4">
						<label
							htmlFor="email"
							className="font-medium flex flex-row items-center justify-between gap-2"
						>
							<span className="flex flex-row items-center gap-2">
								Email{" "}
								<Tooltip text="Enter the email address associated with your account.">
									<CiCircleQuestion className="cursor-pointer" />
								</Tooltip>
							</span>
							{formik.touched.email && formik.errors.email ? (
								<span className="text-[#D61B1B] text-[10px]">
									{formik.errors.email}
								</span>
							) : null}
						</label>
						<input
							id="email"
							type="email"
							name="email"
							placeholder="john@acmeco.com"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.email}
							className="w-full mt-2 px-3 py-2 bg-white text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
						/>
					</div>

					{errorShowing && (
						<p className="text-[#D61B1B] text-xs mt-2 mb-4">
							It seems like you don’t have an account with us yet. Need one?
							Sign up now! If this doesn’t seem right, reach out to Customer
							Support for assistance.
						</p>
					)}
					<button
						type="submit"
						className="w-full px-4 py-2 text-white font-medium bg-[#b0b5c9] hover:bg-indigo-500 active:bg-indigo-600 rounded-lg duration-150"
					>
						Reset Password
					</button>
				</form>
			) : (
				<div>
					<div className="my-5 flex gap-1 items-center">
						<IoIosCheckmarkCircle className=" w-5 h-5 text-green-600" />
						<p className="font-bold">{errorMessage}</p>
					</div>
					<Link to="/" className="text-[16px]">
						Click here to{" "}
						<span className="text-[#9cabd7] text-[16px] hover:underline">
							Sign in
						</span>
					</Link>
				</div>
			)}
		</AuthLayout>
	);
};

export default ResetPassword;
