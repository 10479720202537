import React from "react";
import AuthLayout from "../../layouts/AuthLayout";
import Logo from "../../assets/images/logo/logo.png";
import { CiBellOn } from "react-icons/ci";

import AuthImage2 from "../../assets/images/backgrounds/AuthImage2.png";
import { Link } from "react-router-dom";

const ResetPasswordLink = () => {
	return (
		<AuthLayout
			title="Reset your password"
			description="Don’t worry, we’ve got you. Enter your email, and we’ll send instructions to reset your password."
			backgroundImage={AuthImage2}
			backgroundText="Its okay. We all forget our passowrds."
			additionalInfo={
				<Link to="/onboarding">
					Need an Account?{" "}
					<span className="text-[#9cabd7] hover:underline">Sign up</span>
				</Link>
			}
		>
			<>
				{/* Main Content as children */}
				<div className="mt-5 space-y-2">
					<p className="font-bold pt-12">
						Please check your email for the reset link.
					</p>
				</div>
			</>
		</AuthLayout>
	);
};

export default ResetPasswordLink;
